/**
 * This file is DATA_SOURCES Constant file
 * 
 * author u1100081
 * 
 * Version History :
 *   Date              Name             Reason for modification
 * 14-Nov-2022      Pankaj Pal              IVPSD-4526
 * 16-Apr-2024		Naresh Ginjupalli				  IVPSD-5866
 */
export const DATA_SOURCES = {
  VAERS: {
    caption: 'VAERS',
    type: 'VAERS',
    color: '#ff7043',
    order: 0,
  },
  FAERS: {
    caption: 'FAERS',
    type: 'FAERS',
    color: '#ffb74d',
    order: 1,
  },
  ARGUS: {
    caption: 'Oracle Argus',
    type: 'ARGUS',
    color: '#00bcd4',
    order: 2,
  },
   IVP: {
    caption: 'IVP',
    type: 'IVP',
    color: '#00d49f',
    order: 3,
  },
  //IVPSD-4526
  Literature: {
    caption: 'Literature',
    type: 'Literature',
    color: '#00d49f',
    order: 4,
  },
  HealthAuthority: {
    caption: 'HealthAuthority',
    type: 'HealthAuthority',
    color: '#00d49f',
    order: 5,
  },
  Other: {
    caption: 'Other',
    type: 'Other',
    color: '#00d49f',
    order: 6,
  },
  EVDAS: {
    caption: 'EVDAS',
    type: 'EVDAS',
    color: '#000080',
    order: 7,
  }
};
