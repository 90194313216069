import { Injectable } from '@angular/core';
import {HttpClient, HttpResponse} from "@angular/common/http";
import {map} from "rxjs/operators";
import {environment} from "../../../environments/environment";
import {Observable, Subject} from "rxjs";
import {ModuleType} from "../../shared/enums/module-type.enum";

import {AuthService} from "../../shared/services/auth.service";
import {ISecurityConfig} from '../../shared/interfaces/security.interface';


const SESSION_EXPIRATION_DELAY = 5;

@Injectable({
  providedIn: 'root'
})
export class SecurityService {
  public securityConfig; //todo: add type
  private timeoutID: null | ReturnType<typeof setTimeout> = null;
  private seconds: number = 0;
  private timerIsOn = false;
  private idleTime: number;
  public sessionWarning: Subject<any> = new Subject();
  public userInactive: Subject<any> = new Subject();

  constructor(private http: HttpClient, private authService: AuthService) { }

  public checkToken(token: string): Observable<any> {
    return this.http.get(`${environment.baseUrl}/api/v1/user/security/password/reset?token=${token}`);
  }

  public changePassword(password: string, token: string): Observable<any> {
    return this.http.put(`${environment.baseUrl}/api/v1/user/security/password/reset`, {password: password, token: token});
  }

  public getSecurityConfig(): Observable<ISecurityConfig> {
    return this.http.get<ISecurityConfig>(`${environment.baseUrl}/api/v1/app/security/config`).pipe(
      map((response) => {
         this.securityConfig = response;
         this.securityConfig.idleTimeout = response.idleTimeout * 60;
         return this.securityConfig;
      })
    );
  }

  public updateSecurityConfig(body: ISecurityConfig): Observable<ISecurityConfig> {
    return this.http.put<ISecurityConfig>(`${environment.baseUrl}/api/v1/admin/config`, body).pipe(
      map((response) => {
        this.securityConfig = response;
        this.securityConfig.idleTimeout = response.idleTimeout * 60;
        return this.securityConfig;
      })
    );
  }

  public resetPassword(email: string, moduleType: ModuleType ): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(`${environment.baseUrl}/api/v1/user/security/password/reset`,
      {email: email, moduleType: moduleType}).pipe();      
  }

  public getAppVersion(): Observable<any> {
    return this.http.get(`${environment.baseUrl}/api/v1/app/version`);
  }

  public expireAllPasswords(): Observable<void> {
    return this.http.post<void>(`${environment.baseUrl}/api/v1/tenants/passwords/expire`, null);
  }

  public initSession(idleTimeTrigger): void {
    this.idleTime = this.getIdleTimeWithServerDelay(idleTimeTrigger);
    this.startCount();
  }

  public incrementCount(): void {
    const warningTime = this.getIdleTimeWithServerDelay(this.securityConfig.idleTimeout) - 100;
    if (this.seconds >= this.idleTime) {
      this.stopCount();
      this.userInactive.next();
    }
    else {
      this.seconds = this.seconds + 1;
      this.timeoutID = setTimeout(() => { this.incrementCount(); }, 1000);
      if(this.seconds === warningTime && this.authService.isLoggedIn()) {
        this.sessionWarning.next();
      }
    }
  }

  public startCount(): void {
    if (!this.timerIsOn) {
      this.timerIsOn = true;
      this.incrementCount();
    }
  }

  public resetCount(): void {
    clearTimeout(this.timeoutID);
    this.seconds = 0;
    this.timerIsOn = false;
    this.startCount();
  }

  public stopCount(): void {
    clearTimeout(this.timeoutID);
    this.timerIsOn = false;
  }



  private getIdleTimeWithServerDelay(idleTime: number): number {
    return idleTime - SESSION_EXPIRATION_DELAY;
  }
}
