import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject, Observable, of, Subject} from "rxjs";
import {catchError, map, publishLast, refCount} from "rxjs/operators";

import {environment} from '../environments/environment';
import {IUser, IUserUpdate} from './shared/interfaces/user.interfaces';
import {IVersion} from "./shared/interfaces/version.interface";
import {IDrugGroup, IUserDrugGroup} from './signal-pro/interfaces/drug-group';
import {FilterService} from './signal-pro/services/filter.service';
import {IThreshold} from './signal-pro/interfaces/threshold';

/**
 * Purpose: ITenantDataSource
 * Version History :
 * Date              Name                   Reason for modification
 * 04-Mar-2024     Naresh Ginjupalli               IVPSD-5586
 */

export interface ITenantDataSource {
  id: number;
  updatedAt: string;
  type: string;
  tenantId: number;
  newTreshold: IThreshold;
  activeThreshold: IThreshold;
  disabled?: boolean;
}

@Injectable({
  providedIn: 'root',
})

/** 
Purpose: To make http calls 
Version History :
Date              Name             -      Reason for modification
03-Apr-2024     Pankaj Pal                IVPSD-4550
30-07-2024      Pankaj Pal                IVPSD-6703
*/
export class InfoService {
  public runningUser$: Observable<any>;
  public _runningUser: BehaviorSubject<any>;
  public dataSources$: Observable<Array<string>>;
  public _dataSources: BehaviorSubject<Array<string>>;
  public thresholds$: Observable<any>;
  public thresholds: BehaviorSubject<any>;
  // IVPSD-4550
  public userBucketInfo$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public userBucketInfo =this.userBucketInfo$.asObservable();
  public onUpdateSharedGroupTable = new Subject();

  constructor(private http: HttpClient, private filterService: FilterService) {
    this._runningUser = new BehaviorSubject<any>(null);
    this.runningUser$ = this._runningUser.asObservable();
    this._dataSources = new BehaviorSubject<Array<string>>(null);
    this.dataSources$ = this._dataSources.asObservable();
    this.thresholds = new BehaviorSubject<any>(null);
    this.thresholds$ = this.thresholds.asObservable();
  }

  public get userInfo(): Observable<IUser> {
    if (this._runningUser.value) {
      return of(this._runningUser.value);
    } else {
      return this.getInfo();
    }
  }

  public getInfo(): Observable<IUser> {
    return this.http.get<IUser>(`${environment.baseUrl}/api/v1/users/me`)
      .pipe(
        map((response: IUser) => {
          this._runningUser.next(response);
          if(response.userConfig) {
            const filter = response.userConfig.filter;
            if (response.userConfig.userDrugGroup) {
              const drugGroup = response.userConfig.userDrugGroup.drugGroup;
              filter.drugIds = drugGroup.drugs.map(d => d.drugId);
            } else {
              filter.drugIds = []
            }
            let sources = response.userConfig.dataSources;
            this._dataSources.next(sources);
            this.filterService.excludedSignals$.next(false);
            this.filterService.setFilter({
              filter,
              dataMode: response.userConfig.dataMode,
              dataModeScope: response.userConfig.dataModeScope,
              dataSources: response.userConfig.dataSources
            });
            this.filterService.tenantDataSources$.next(response.tenant.dataSources);
          }
          return response;
        }),
        publishLast(),
        refCount(),
        catchError(() => {
          return of(null);
        })
      );
  }

  public setUserInfo(user: IUser): void {
    this._runningUser.next(user);
  }

  public updateCurrentUser(body: IUserUpdate): Observable<IUser> {
    return this.http
      .put<IUser>(`${environment.baseUrl}/api/v1/users/me`, body)
      .pipe(
        map((user: IUser) => {
          this._runningUser.next(user);
          return user;
        })
      );
  }

  public setFavoriteGroup(
    userId: string,
    drugGroup: IDrugGroup
  ): Observable<any> {
    return this.http.post(
      `${environment.baseUrl}/api/v1/drug/group`,
      this.prepareSendDrugGroup(drugGroup, userId)
    );
  }

  public saveChangesFavouriteGroup(
    id: number,
    drugGroupName: string
  ): Observable<void> {
    return this.http.put<void>(
      `${environment.baseUrl}/api/v1/drug/group?userDrugGroupId=${id}&name=${drugGroupName}`, {}
    );
  }

  public removeFavoriteGroup(id: number): Observable<any> {
    return this.http.delete(`${environment.baseUrl}/api/v1/drug/group?userDrugGroupId=${id}`);
  }

  public setActiveGroup(
    userId: string,
    drugGroup: IDrugGroup,
    fav?: boolean
  ): Observable<any> {
    let group;
    fav
      ? (group = drugGroup)
      : (group = this.prepareSendDrugGroup(drugGroup, userId));

    return this.http.post(
      `${environment.baseUrl}/api/v1/drug/group?active=true`,
      group
    );
  }

  private prepareSendDrugGroup(drugGroup: IDrugGroup, userId: string): any {
    return {
      name: drugGroup.name,
      active: drugGroup.active,
      favorite: drugGroup.favorite,
      id: drugGroup.id,
      shared: drugGroup.shared,
      userId,
      drugGroup,
    };
  }

  public applyFilter(filter) {
    return this.http.post(`${environment.baseUrl}/api/v1/filters`, filter);
  }

  public updateThresholds(thresholds) {
    this.thresholds.next(thresholds);
    return this.http.post(
      `${environment.baseUrl}/api/v1/thresholds`,
      thresholds
    );
  }

  public getVersion(): Observable<IVersion> {
    return this.http.get<IVersion>(`${environment.baseUrl}/api/v1/app/version`)
  }

  public getAllUserDrugGroups(isCreatedByUser, searchTerm, params): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/api/v1/drug/group/all?isCreatedByUser=${isCreatedByUser}&searchTerm=${searchTerm}&${params}`)
  }

  public removeSharedGroup(id: number): Observable<any> {
    return this.http.delete(`${environment.baseUrl}/api/v1/drug/group/shared?userDrugGroupId=${id}`);
  }

  // IVPSD-6703
  public get bucketInfo(): Observable<any> {
    if (this.userBucketInfo$.value) {
      return of(this.userBucketInfo$.value);
    } else {
      return this.loadSourceBucket();
    }
  }

    // IVPSD-4550
    public loadSourceBucket():Observable<any>{
      return  this.http.get<any>(`${environment.baseUrl}/api/v1/users/bucket/tenantId`).pipe(
        map((response: any) => {
          this.userBucketInfo$.next(response);
          return response;
        }))
      }
}
