import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
/**  
 Purpose: Displays Admin users details in UI
 Version History :
 Date              Name             -      Reason for modification
 14-Feb-2023     Pankaj Pal                   IVPSD-2099
*/
export class ConfirmModalComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  //IVPSD-2099
  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(true);
}

//IVPSD- 2099
onNoClick(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
}
}
