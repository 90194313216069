import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';

import {environment} from '../../../environments/environment';
import {SessionType} from '../../shared/enums/session.enum';
import {UserRoles} from "../../shared/enums/user-roles.enum";
import {ISessionsData,ISession} from '../../shared/interfaces/session.interface';
import {IUser} from '../../shared/interfaces/user.interfaces';
import {IManagers} from "../interfaces/managers.interface";
import {ITenantSettings} from "../interfaces/tenant-settings.interface";

@Injectable({
  providedIn: 'root'
})

/**
 * This file is for make the http call for search page 
 * 
 * author u1101818
 * 
 * Version History :
 *   Date              Name             Reason for modification
 * 3-July-2024     Pankaj Pal               IVPSD-6011
 * 07-Jul-2024     Pankaj Pal               IVPSD-6777
 * 
 */
export class UserService {
  public users$: Observable<IUser[]>;
  public users: BehaviorSubject<IUser[]>;
  public usersFilter$: Observable<any>;
  public usersFilter: BehaviorSubject<any>;
  public usersSession$: Observable<any>;
  public usersSession: BehaviorSubject<any>;
  // IVPSD-6011
  // IVPSD-6777
  public enumLookUpData$: Observable<any>;
  public enumLookUpData: BehaviorSubject<any> =new BehaviorSubject<any>(null);;


  constructor(private http: HttpClient) {
    this.users = new BehaviorSubject<IUser[]>(null);
    this.users$ = this.users.asObservable();
    this.usersFilter = new BehaviorSubject<any>(null);
    this.usersFilter$ = this.usersFilter.asObservable();
    this.usersSession = new BehaviorSubject<any>(null);
    this.usersSession$ = this.usersSession.asObservable();
    this.enumLookUpData = new BehaviorSubject<any>(null);
    this.enumLookUpData$ = this.usersSession.asObservable();
  }

  public getUsers(): Observable<IUser[]> {
    if (this.users.getValue()) {
      return of(this.users.getValue());
    } else {
      return this.http.get<IUser[]>(`${environment.baseUrl}/api/v1/users`).pipe(
        map((response: IUser[]) => {
          this.users.next(response);
          return response;
        })
      );
    }
  }


  // IVPSD-6011 && IVPSD-6777
  public loadAllEnums():Observable<any> {
    if (this.enumLookUpData.value !=null) {
      return of(this.enumLookUpData.value);
    } else {
    const options = {
      headers: new HttpHeaders({
        'secret-key': 'IVPS'
      }),
    };
    return this.http.get<any>(`${environment.baseUrl}/api/v1/enum/loadLookupDataByTenantId`,options).pipe(
      map((response) => {
        this.enumLookUpData.next(response);
        return response;
      })
    );
  }

  }

  public createUser(user): Observable<any> {
    return this.http.post(`${environment.baseUrl}/api/v1/users`, user).pipe(
      map((response: any) => {
        this.users.next([...this.users.getValue(), response]);
      })
    );
  }

  public editUser(user): Observable<any> {
    return this.http.put(
      `${environment.baseUrl}/api/v1/users?userId=${user.id}`,
      user
    );
  }

  public updateUsersList(user): void {
    const users = this.users.getValue();
    const index = users.findIndex(x => x.id === user.id);
    users[index] = user;
    this.users.next(users);
  }

  public changeUserActive(user, isActive) {
    const users = this.users.getValue();
    return this.http.put(`${environment.baseUrl}/api/v1/users/status?userId=${user.id}&isActive=${isActive}`, null).pipe(
      map(() => {
        const foundIndex = users.findIndex(x => x.id == user.id);
        users[foundIndex].active = !user.active;
        this.users.next(users);
    }));
  }

  public getUserSessions(
    userId: string,
    pageIndex: number = 0,
    sortActive: string = 'createdAt',
    sortDirection: string = 'desc'
  ): Observable<ISessionsData> {
    return this.http.get<ISessionsData>(`${environment.baseUrl}/api/v1/sessions/users?userId=${userId}&type=${SessionType.All}&page=${pageIndex}&size=10&sort=${sortActive},${sortDirection}`
    );
  }


  public getUserFailedSessions(
    userId: string,
    pageIndex: number = 0,
    sortActive: string = 'createdAt',
    sortDirection: string = 'desc'
  ): Observable<ISessionsData> {
    return this.http.get<ISessionsData>(`${environment.baseUrl}/api/v1/sessions/users?userId=${userId}&type=${SessionType.FailedLogin}&page=${pageIndex}&size=10&sort=${sortActive},${sortDirection}`
    );
  }

  public loadSessionsByIp(
    ipAddress: string,
    pageIndex: number,
    sortActive: string,
    sortDirection: string
  ): Observable<ISessionsData> {
    return this.http.get<ISessionsData>(`${environment.baseUrl}/api/v1/sessions/ip?ipAddress=${ipAddress}&page=${pageIndex}&size=10&sort=${sortActive},${sortDirection}`);
  }

  public loadSessionsByAdminIp(
    ipAddress: string,
    pageIndex: number,
    sortActive: string,
    sortDirection: string
  ): Observable<ISessionsData> {
    return this.http.get<ISessionsData>(`${environment.baseUrl}/api/v1/sessions/admin/ip?ipAddress=${ipAddress}&page=${pageIndex}&size=10&sort=${sortActive},${sortDirection}`);
  }


    public getAllUserSessions(
    userId: string,
    pageIndex: number = 0,
    sortActive: string = 'createdAt',
    sortDirection: string = 'desc'
  ): Observable<ISession[]> {
    return this.http.get<ISession[]>(`${environment.baseUrl}/api/v1/sessions/users/all?userId=${userId}&type=${SessionType.All}&sort=${sortActive},${sortDirection}`
    );
  }

   public getAllUserFailedSessions(
    userId: string,
    pageIndex: number = 0,
    sortActive: string = 'createdAt',
    sortDirection: string = 'desc'
  ): Observable<ISession[]> {
    return this.http.get<ISession[]>(`${environment.baseUrl}/api/v1/sessions/users/all?userId=${userId}&type=${SessionType.FailedLogin}&sort=${sortActive},${sortDirection}`
    );
  }

  public loadAllSessionsByIp(
    ipAddress: string,
    pageIndex: number,
    sortActive: string,
    sortDirection: string
  ): Observable<ISession[]> {
    return this.http.get<ISession[]>(`${environment.baseUrl}/api/v1/sessions/ip/all?ipAddress=${ipAddress}&sort=${sortActive},${sortDirection}`);
  }

   public loadAllSessionsByAdminIp(
    ipAddress: string,
    pageIndex: number,
    sortActive: string,
    sortDirection: string
  ): Observable<ISession[]> {
    return this.http.get<ISession[]>(`${environment.baseUrl}/api/v1/sessions/admin/ip/all?ipAddress=${ipAddress}&sort=${sortActive},${sortDirection}`);
  }

  public checkEmailExists(email: string) {
    return this.http.get(
      `${environment.baseUrl}/api/v1/user/emailExists/${email}`
    );
  }

  public getUserById(userId: string): IUser {
    const users = this.users.getValue();
    return users.find(user => user.id === userId);
  }

  public resetUserPassword(userId): any {
    return this.http.post(
      `${environment.baseUrl}/api/v1/user/management/password/reset?userId=${userId}`,
      null
    );
  }

  public updateTenantSettings(tenantSettings: ITenantSettings): Observable<void> {
    return this.http.put<void>(`${environment.baseUrl}/api/v1/tenants/admin/settings`, tenantSettings);
  }

  public getUsersManagers(): Observable<IManagers> {
    return this.http.get<IManagers>(`${environment.baseUrl}/api/v1/users/managers`);
  }

  public isEnterpriseSupport(user: IUser): boolean {
    return user && user.roles && user.roles.includes(UserRoles.EnterpriseSupport);
  }

  public isEnterpriseAdmin(user: IUser): boolean {
    return user && user.roles && user.roles.includes(UserRoles.EnterpriseAdministrator)
  }

  public isTenantAdministrator(user: IUser): boolean {
    return user && user.roles && user.roles.includes(UserRoles.TenantAdministrator);
  }

  public isDataManager(user: IUser): boolean {
    return user && user.roles && user.roles.includes(UserRoles.DataManager);
  }

  public isUserManager(user: IUser): boolean {
    return user && user.roles && user.roles.includes(UserRoles.UserManager);
  }

  public isWorkflowManager(user: IUser): boolean {
    return user && user.roles && user.roles.includes(UserRoles.WorkflowManager);
  }

  public isReadOnlyUser(user: IUser): boolean {
    return user && user.roles && user.roles.includes(UserRoles.UserReadOnly);
  }

}
