import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {map, switchMap, tap} from 'rxjs/operators';

import {environment} from '../../../environments/environment';
import {AdminService} from '../../admin/services/admin.service';
import {InfoService} from '../../info.service';
import {UserService} from '../../signal-pro/services/user.service';

export enum StorageKeys {
  isAuthorized = 'isAuthorized',
  isAdmin = 'isAdmin',
}
export const DEFAULT_HOME_PAGE_ROUTE = '/signal-pro';

@Injectable({
  providedIn: 'root',
})

/** 
Purpose: To make http calls 
Version History :
Date              Name             -      Reason for modification
03-Apr-2024     Pankaj Pal                IVPSD-4550
*/
export class AuthService {
  private authSubject = new BehaviorSubject<any>(null);
  public signoutSubject = new BehaviorSubject<any>(null);
  public get loggedIn$(): Observable<any> {
    return this.authSubject.asObservable();
  }
  public get signout$(): Observable<any> {
    return this.signoutSubject.asObservable();
  }
  public userActivity: Subject<any> = new Subject();

  constructor(
    private http: HttpClient,
    private infoService: InfoService,
    private userService: UserService,
    private adminService: AdminService,
    private router: Router,
    private dialog: MatDialog
  ) {}

  public signin(data): Observable<any> {
    return this.http.post(`${environment.baseUrl}/api/v1/auth`, data).pipe(
      tap(() => this.setAuthorizedFlag()),
      switchMap(() => this.infoService.userInfo),
      tap((result) => {
        this.authSubject.next(result);
        this.userActivity.next();
      })
    );
  }

  public signinAdmin(data): Observable<any> {
    return this.http
      .post(`${environment.baseUrl}/api/v1/admin/auth`, data)
      .pipe(
        tap(() => this.setAuthorizedFlag()),
        switchMap(() => this.infoService.userInfo),
        tap((result) => {
          this.authSubject.next(result);
          localStorage.setItem(StorageKeys.isAdmin, 'true');
        })
      );
  }

  public signinSupport(tenantId: number): Observable<void> {
    return this.http
      .post<void>(
        `${environment.baseUrl}/api/v1/auth/internal/tenants/tenant?tenantId=${tenantId}`,
        null
      )
      .pipe(
        tap(() => {
          if (localStorage.getItem(StorageKeys.isAdmin)) {
            localStorage.removeItem(StorageKeys.isAdmin);
          }
          this.router.navigate([DEFAULT_HOME_PAGE_ROUTE]);
        })
      );
  }

  public isLoggedIn(): boolean {
    return JSON.parse(localStorage.getItem(StorageKeys.isAuthorized));
  }

  public signout() {
    return this.http
      .post(`${environment.baseUrl}/api/v1/auth/logout`, null)
      .pipe(
        map(() => {
          this.userService.users.next(null);
          this.infoService.setUserInfo(null);
          this.adminService.administrators.next([]);
          this.dialog.closeAll();
          this.removeAuthorizedFlag();
        })
      );
  }

  public isAdminModule(route: string): boolean {
    return route.includes('/admin');
  }

  public isAdmin(): boolean {
    return !!JSON.parse(localStorage.getItem(StorageKeys.isAdmin));
  }

  public removeAuthorizedFlag(): void {
    localStorage.removeItem(StorageKeys.isAuthorized);
    if (localStorage.getItem(StorageKeys.isAdmin)) {
      localStorage.removeItem(StorageKeys.isAdmin);
    }
  }

  private setAuthorizedFlag(): void {
    localStorage.setItem(StorageKeys.isAuthorized, 'true');
  }


}
