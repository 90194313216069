<!-- 
 Purpose: Displays Session confirmation pop-up
 Version History :
 Date              Name             -      Reason for modification
 14-Feb-2023     Pankaj Pal                 IVPSD-2099
 13-May-2024     Pankaj Pal                 IVPSD-6556
 -->
<h1 mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-content>
  <p>{{data.textContent}}</p>
</div>
<div mat-dialog-actions align="end">
  <button mat-raised-button  (click)="onNoClick()">{{data.cancelText}}</button>
  <!-- IVPSD -2099 -->
  <button mat-raised-button  [mat-dialog-close]="data.isConfirmed" (click)="onConfirm()" cdkFocusInitial>{{data.okText}}</button>
</div>
