/**
 * This file is for ErrorCodeEnum 
 * 
 * author u1100081
 * 
 * Version History :
 *   Date              Name             Reason for modification
 * 27-Jul-2022	   Naresh Ginjupalli				IVPSD-4059
 * 04-Mar-2024     Naresh Ginjupalli        IVPSD-5586
 * 
 */
export enum ErrorCodeEnum {
  // general error
  SD_0000 = "Internal server error." ,
  SD_0001 = "Backend service is unavailable." ,
  SD_0002 = "Backend service mapping error." ,
  SD_0003 = "Unknown constraint name." ,
  SD_0004 = "Unknown validation violation." ,

  // auth error
  SD_0100 = "Access denied." ,
  SD_0101 = "Authentication error." ,
  SD_0102 = "Reset token is invalid." ,
  SD_0103 = "Please, provide correct Email/Password." ,
  SD_0104 = "Your User is not Active or locked, please contact Administrator." ,
  SD_0105 = "Your User is not Active or locked, please contact Administrator." ,
  SD_0106 = "Your session is expired. Please, login again." ,
  SD_0107 = "Please, provide correct Email/Password.",
  SD_0108 = "Your User is not Active or locked, please contact Administrator.",
  SD_0110 = "User's password was expired",
  // DB constraints violation
  SD_0200 = "This Email already exists." ,
  SD_0201 = "User with the same Full Name already exists." ,
  SD_0202 = "Tenant name should be unique." ,
  SD_0203 = "Template with the same name already exists." ,
  SD_0206 = "Task for event is already created",    // IVPSD-4059 changed the message

  // not found error
  SD_0300 = "Tenant not found." ,
  SD_0301 = "This User not found. Please, provide another one." ,
  SD_0302 = "Filter not found." ,
  SD_0303 = "Task not found." ,
  SD_0304 = "Notification not found." ,
  SD_0305 = "Data source not found." ,

  // bad request error
  SD_0400 = "This is the only one active Enterprise Support, cannot be Deactivated." ,
  SD_0401 = "Password was already used. Please, provide another one." ,
  SD_0402 = "Please provide the correct password." ,
  SD_0403 = "Deactivation of Tenant administrator from tenant module is prohibited." ,
  SD_0404 = "Box API exception.",
  SD_0405 = "Problem with server communication - HTTP message not readable error." ,
  SD_0406 = "Item with the same name is already exists." ,
  SD_0407 = "Item is no longer accessible." ,
  SD_0408 = "Password doesn't match organization policy." ,
  SD_0411 = "Support cannot be updated from tenant module",
  SD_0414 = "This is the only one active Tenant Admin, cannot be Deactivated.",
  SD_0417 = "User cannot modify the task",
  SD_0418 = "Enterprise Support is inactive globally, cannot activate it",
  SD_0422 = "Shared product grouping already exists",
  SD_0423 = "With same products the Shared Custom Group already exist",

  // validation error
  SD_0500 = "Name of user Drug Group must be filled." ,
  SD_0501 = "Drugs must not be empty." ,

  SD_0502 = "Data mode must be filled." ,
  SD_0503 = "Data view mode must not be null." ,
  SD_0504 = "DrugIds must not be empty." ,

  SD_0505 = "filterTemplate.name must not be blank." ,
  SD_0506 = "filterTemplate.filter must not be null." ,

  SD_0507 = "Filter is required." ,
  SD_0508 = "Data Mode in tenant settings must be filled." ,
  SD_0509 = "Measures in tenant settings must be filled." ,
  SD_0510 = "Comparators must not be null." ,
  SD_0511 = "Users Modify Access is required." ,

  SD_0512 = "Drugs Hash is required." ,
  SD_0513 = "Data sources must not be null." ,
  SD_0514 = "Measures are required." ,

  SD_0515 = "filterWithDrugIds.drugIds must not be empty." ,

  SD_0516 = "userUpdateDto.newPassword length must be between 8 and 128." ,
  SD_0517 = "userUpdateDto.oldPassword length must be between 8 and 128." ,
  SD_0518 = "userUpdateDto.details.firstName must not be blank." ,
  SD_0519 = "userUpdateDto.details.lastName must not be blank." ,
  SD_0520 = "userUpdateDto.details.jobTitle must not be blank." ,
  SD_0521 = "userUpdateDto.details.email must be valid." ,
  SD_0522 = "userUpdateDto.userConfig.emailNotification must not be null." ,
  SD_0523 = "userUpdateDto.userConfig.inAppNotifications must not be null." ,
  SD_0524 = "userUpdateDto.userConfig.dataSources must not be null." ,
  SD_0525 = "userUpdateDto.userConfig.measures must not be null." ,
  SD_0526 = "userUpdateDto.userConfig.dataMode must not be null." ,
  SD_0527 = "userUpdateDto.userConfig.dataModeScope must not be null." ,
  SD_0528 = "userUpdateDto.userConfig.filter must not be null." ,

  SD_0529 = "User can't be created without role." ,
  SD_0530 = "User's details must be filled." ,
  SD_0531 = "First Name must not be blank." ,
  SD_0532 = "Last Name must not be blank." ,
  SD_0533 = "Job Title must not be blank." ,
  SD_0534 = "This email address is invalid." ,

  SD_0535 = "Please, provide correct password." ,
  SD_0536 = "Token is required." ,
  SD_0538 = "Assignee must not be null",
  SD_0539 = "Workflow manager must not be null",

}
